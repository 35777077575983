.data-main{
  .loader-name {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 14px 0 #fff, -14px 0 #fff;
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;
    left: 5px;
  }
  
  @keyframes flash {
    0% {
      background-color: #FFF2;
      box-shadow: 14px 0 #FFF2, -14px 0 #FFF;
    }
  
    50% {
      background-color: #FFF;
      box-shadow: 14px 0 #FFF2, -14px 0 #FFF2;
    }
  
    100% {
      background-color: #FFF2;
      box-shadow: 14px 0 #FFF, -14px 0 #FFF2;
    }
  }
}

.loader-name {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #000;
  box-shadow: 14px 0 #000, -14px 0 #000;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
  left: 5px;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 14px 0 #FFF2, -14px 0 #fff;
  }

  50% {
    background-color: #fff;
    box-shadow: 14px 0 #FFF2, -14px 0 #ffff;
  }

  100% {
    background-color: #FFF2;
    box-shadow: 14px 0 #ffff, -14px 0 #FFF2;
  }
}