.profileScreen {
  background: white !important;
  min-height: 100vh;
}

.backColor,
.backColor > li > a {
  background: white !important;
  color: #000;
}
.data-main {
  .backColor,
  .backColor > li > a {
    background: black !important;
    color: #fff;
  }

  .line-para {
    line-height: 25px;
    font-size: 17px;
    color: white;
  }
  .personalityPara {
    color: #fff;
  }
  .profileScreen {
    background: black !important;
    min-height: 100vh;
  }

  .profileContent1 > a {
    text-decoration: none !important;
    color: #ffffff;
  }

  .profileContent1 > a:hover {
    text-decoration: none !important;
    color: #ffffff;
  }

  .profilePara1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #fff !important;
  }
  .profilePara2 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
  }

  .famousbottom {
    border: 2px solid #1c1c1f;
    background: #1c1c1f;
  }

  .section-titlePersonality {
    /* / max-width: 85% !important; / */
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  .section-descriptionPersonality {
    /* / width: 85% !important; / */
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
  }

  .footer-relationPersonality {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #ffffff;
  }

  .connection_btn {
    width: 100px;
    height: 33px;
    top: 15px;
    left: 221px;
    border-radius: 10px;
    background: #1c1c1f;
    border: none;
    color: #fff;
    font-family: SF Pro Text;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
  }
}
.line-para {
  line-height: 25px;
  font-size: 17px;
  color: black;
  text-transform: lowercase;
}

.line-para::first-letter {
  text-transform: uppercase;
}
.personalityPara {
  color: #000;
}

.profileHeading {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
}

.uploadimageUser {
  align-items: center;
  /* / background-color: #dcdcdc; / */
  display: flex;
  height: 7.9rem;
  justify-content: center;
  width: 7.9rem;
  border-radius: 100%;
  /* / border: 1px solid #fff; / */
  /* / box-shadow: 0px 0px 10px #fff; / */
  position: relative;
}

.camera {
  position: absolute;
  bottom: 5px;
  right: 8px;
  font-size: 20px;
  /* margin: -3rem 0rem 0rem 1.7rem; */
  color: #cacaca;
  text-decoration: none;
}
.data-main {
  .camera {
    position: absolute;
    bottom: 5px;
    right: 8px;
    font-size: 20px;
    /* margin: -3rem 0rem 0rem 1.7rem; */
    color: #e0e0e0;
    text-decoration: none;
  }
}

.profilePara1 {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #000 !important;
}

.profilePara2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000;
}

.profileCard {
  /* background-color: #292929 !important; */
  border-radius: 5px;
  max-width: 50% !important;
  /* padding: 1.5rem 0rem 1.5rem 0rem; */
}

.ProfileBtn {
  border: 1px solid #000 !important;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-decoration: none;
  color: #fff !important;
  border-radius: 5px;
  background-color: #000;
  font-size: 12px;
}

.ProfileBtn:hover {
  text-decoration: none;
  color: #ffffff;
}

.profileContent1 {
  cursor: pointer;
  text-decoration: none !important;
}

.profileContent1 > a {
  text-decoration: none !important;
  color: #000;
}

.profileContent1 > a:hover {
  text-decoration: none !important;
  color: #000;
}

.profileUserName {
  font-family: "AppleMyungjo";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.profileFont {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
}

.fontPro {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.profileContentFont {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
}

.cardHeading {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}

.CardSubhaeding {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* / line-height: 10px; / */
}

/* / ..............................Personality ............................. / */

.personalityBtn {
  display: flex;
  justify-content: center;
}

.personalityContent {
  text-align: center;
}

.personalityBtn:focus {
  color: #ffffff !important;
}

.Btn01 {
  padding: 0rem 3.5rem 2rem 3.5rem;
  background-color: black !important;
  border: none;
  color: #6d7278;
}

.Btn01:focus {
  border-bottom: #ffffff 2px solid !important;
  color: #ffffff !important;
}

.Btn02 {
  padding: 0rem 3.5rem 2rem 3.5rem;
  background-color: black !important;
  border: none;
  color: #6d7278;
}

.Btn02:focus {
  border-bottom: #ffffff 2px solid !important;
  color: #ffffff !important;
}

.personalityImg {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.imagePersonality {
  display: flex;
  justify-content: flex-start;
  /* height: 240px; */
  flex-direction: row;
  align-items: stretch;
}

.personalityBtns {
  display: flex;
  justify-content: space-evenly;
}

.famousImg {
  width: 80px;
  height: 80px;
}

.famousContent {
  color: #6d7278;
  font-size: 11px;
}

.famousbottom {
  border: 2px solid #dedede;
  background: #cbcbcd;
}

.nav-link.active > .side01 {
  background: transparent;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
}

.side01 {
  background: transparent;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
}

.data-main {
  .side01 {
    background: transparent;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
  }
}
/* / ...............................Friends screen.................................... / */

.friendIcon {
  color: #fbbc95;
  transform: rotateY(180deg);
  rotate: 15deg;
}

/* / ........................................pending screen......................... / */
.pendingFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pendingBtn {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  border-radius: 5px;
  padding: 0.2rem 1rem 0.2rem 1rem;
  cursor: pointer;
  margin-top: 0.2rem;
  background-color: #292929 !important;
}

.pendingPara {
  display: flex;
  justify-content: space-between;
}

.pendingContent {
  vertical-align: middle;
}

#friendList {
  padding-right: 4rem !important;
}

#pendingList {
  padding-right: 2rem !important;
}

@media (min-width: 992px) and (max-width: 1042px) {
  .res-card1 {
    margin-top: 0px !important;
  }

  .res-card1-btn {
    padding-top: 6px !important;
  }
}

@media (min-width: 1042px) and (max-width: 1192px) {
  .res-card1 {
    margin-top: 10px !important;
  }

  .res-card1-btn {
    padding-top: 15px !important;
  }
}

@media (min-width: 1193px) and (max-width: 1200px) {
  .res-card1-btn {
    padding-top: 24px !important;
  }
}
@media (min-width: 200px) and (max-width: 300px) {
  .profileCard {
    /* background-color: black; */
    border-radius: 5px;
    max-width: 100% !important;
  }
  .CardSubhaeding {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
  }
}

pre {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 1rem !important;
}

/* / ............................changeimg.................................. / */

.getImg {
  height: 500px !important;
  border-radius: 5px;
  border: 1px solid #ffffff;
}

.contain-img {
  position: relative;
  width: 46%;
}

.pos_ition {
  position: absolute;
  right: 13px;
  top: 10px;
  margin-top: -0.5rem;
}

.avtar {
  margin-left: 1rem;
  cursor: pointer;
}

.avtar2 {
  margin-left: 6rem;
  cursor: pointer;
}

.addProfile {
  height: 240px;
  /* width: 192px; */
  width: 100%;
}

.data-main {
  .uploadImage01 {
    height: 24rem;
    width: 16rem;
    background-color: #0b0a11;
    border-radius: 15px;
    border: 1px solid #0b0a11;
  }

  .uploadImage_change {
    background-color: #0b0a11;
    border-radius: 15px;
    border: 1px solid #0b0a11;
  }
}
.uploadImage01 {
  height: 24rem;
  width: 16rem;
  background-color: #f3f2f9;
  border-radius: 15px;
  border: 1px solid #f3f2f9;
}

.plus {
  font-size: 5rem;
  cursor: pointer;
  color: #fff;
  padding-top: 10px;
  margin-top: 1.5rem;
}

.plus1 {
  height: 24rem;
  width: 16rem;
  position: relative;
  top: -17px;
  left: -0px;
  background-color: rgba(245, 245, 245, 0.527);
  border-radius: 15px;
  border: 1px solid #0b0a11;
  object-fit: cover;
}

.plusv2 {
  height: 200px;
  width: 168px;
  margin-top: -9.5rem;
}

.imgset {
  display: grid;
  /* / flex-direction: row; / */
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

@media (min-width: 250px) and (max-width: 650px) {
  .imgset {
    display: grid;
    /* / flex-direction: column; / */
    justify-content: center;
  }

  .avtar {
    margin-left: 0rem;
    cursor: pointer;
  }

  .avtar2 {
    margin-left: 8.5rem;
    cursor: pointer;
  }
}

@media (min-width: 200px) and (max-width: 300px) {
  .getImg {
    height: 500px !important;
    /* / margin-left: -2rem; / */
  }
}
@media (min-width: 301px) and (max-width: 450px) {
  .getImg {
    height: 500px !important;
    /* / margin-left: -2rem; / */
  }
}
@media (min-width: 451px) and (max-width: 650px) {
  .getImg {
    height: 500px !important;
    /* / margin-left: -2rem; / */
  }
}

@media (max-width: 992px) {
  .headerBAr {
    display: none;
  }
}

.profileUpperpart {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 992px) {
  /* .navMatchbottom1 {
        display: block;
    } */
}

/* / //pagination / */

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: black !important;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: black !important;
  --bs-pagination-hover-bg: #fff !important;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #fff !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: black !important;
  --bs-pagination-active-border-color: #fff !important;
  --bs-pagination-disabled-color: black !important;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item > a {
  color: #fff !important;
}

.page-link:hover {
  color: black !important;
}

.page-item.active {
  color: #fff !important;
}

.active > .page-link,
.page-link.active {
  color: rgb(249, 249, 249) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

@media (min-width: 300px) and (max-width: 576px) {
  .cardHeading {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
  }

  .CardSubhaeding {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    /* / line-height: 10px; / */
  }
}

@media (min-width: 250px) and (max-width: 328px) {
  .cardHeading {
    line-height: 17px;
    font-size: 10px;
  }
}

@media (min-width: 200px) and (max-width: 350px) {
  .pendingContent {
    font-size: 12px !important;
  }

  .famousContent {
    font-size: 10px;
  }

  .pendingBtn {
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  }

  .profileContentFont {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 20px !important;
  }
}

.cardsimg {
  padding: 1rem;
  object-fit: contain;
}

@media (max-width: 992px) {
  .cardsimg {
    height: 120px;
    padding: 1rem;
    object-fit: contain;
  }

  .res-card1-btn {
    margin-top: 6px !important;
  }
}

@media (min-width: 992px) {
  .cardsimg {
    height: 120px;
    object-fit: contain;
  }

  .profileScreen {
    padding: 0 10rem !important;
  }
}

.boxSquare {
  border: 1px solid #141313;
  border-radius: 5px;
  height: 30px;
  padding: 3px;
  width: 30px;
  cursor: pointer;
  background-color: #292929;
}

.profilePadding {
  margin-left: 80px;
}

/* @media (min-width:768px) {
    .paddingFriends{
        margin-left: 10px;
    }
} */
@media (max-width: 992px) {
  .paddingFriends {
    margin-left: -20px;
  }
}

.PointAdd {
  cursor: pointer;
}

.tab-content {
  text-align: center;
}

/*personality7 */

.weekPersonality {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
  /* / width: 60% !important; / */
  margin: 10px auto !important;
}

.week-blockPersonality {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  font-family: "Playfair Display", serif !important;
}

.week-headerPersonality {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  /* / color: #fff; / */
}

.datePersonality {
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  /* / line-height: 34px; / */
  /* / color: #FFFFFF; / */
}

.date1Personality {
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 34px;
  /* / color: #FFFFFF; / */
}

.andPersonality {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 20px;
  text-align: center;
  /* / color: #FFFFFF; / */
}

.calledPersonality {
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  /* / line-height: 40px; / */
  text-align: center;
  /* / color: #FFFFFF; / */
  mix-blend-mode: normal;
  /* / width: 50% !important; /// */
  /* / margin: 100px auto !important; / */
  /* / margin-bottom: 100px; / */
  /* / margin-bottom: 10px !important; / */
}

.image-section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 auto !important;
  /* / / width: 300px !important; / / */
  height: 300px !important;
}

/* .border-iconPersonality {
    width: 300px !important;
    height: 300px !important;
} */

.rel-namePersonality {
  font-family: Times;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 66px;
  text-align: center;
  text-transform: capitalize;
  /* / color: #fff; / */
}

.week2Personality {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  /* / width: 60% !important; / */
  /* / margin: 100px auto !important; / */
  flex-wrap: wrap !important;
}

.week-block2Personality {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.idealPersonality {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  /* / color: #FFFFFF; / */
}

/* .reIdealPersonality {
    color: #fff;
} */

.description-sectionPersonality {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin: 0 auto !important;
  /* width: 100vw !important; */
}

.section-titlePersonality {
  /* / max-width: 85% !important; / */
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
}

.section-descriptionPersonality {
  /* / width: 85% !important; / */
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  mix-blend-mode: normal;
}

.footer-relationPersonality {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #0000;
}

@media screen and (max-width: 576px) {
  .weePersonalitykPersonality {
    justify-content: space-around !important;
    margin: 30px auto !important;
  }

  /* .week-headerPersonality {
    font-size: 18px;
    line-height: 20px;
  } */
  /* 
  .datePersonality {
    font-size: 16px;
    line-height: 18px;
  } */

  .week {
    width: 90% !important;
  }

  /* 
  .calledPersonality {
    font-size: 18px;
    line-height: 22px;
    margin: 10px auto !important;
  } */

  .date1Personality {
    font-size: 16px;
    line-height: 18px;
    /* margin-right: 2rem !important; */
  }

  .section-titlePersonality {
    line-height: 36px !important;
  }

  .section-descriptionPersonality {
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .footer-relationPersonality {
    font-size: 13px;
    line-height: 14px;
  }

  .footer-linePersonality {
    width: 80% !important;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .week {
    justify-content: space-around !important;
    margin: 30px auto !important;
  }

  /* .week-headerPersonality {
    font-size: 26px;
    line-height: 25px;
  } */
  /* 
  .calledPersonality {
    font-size: 30px;
    line-height: 30px;
    margin: 10px auto !important;
  } */
}

.personality6data {
  font-size: 15px;
  font-weight: lighter;
  text-transform: initial  !important;
}

.personality6data::first-letter {
  text-transform: uppercase !important;
}
.advice-p {
  /* max-width: max-content; */
  white-space: pre-wrap;
}

.connection_btn {
  width: 100px;
  height: 33px;
  top: 15px;
  left: 221px;
  border-radius: 10px;
  background: #ededed;
  border: none;
  color: #000;
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
}
.switch_div {
  background: #7676803d;
  border-radius: 35px;
}
.nav-link {
  transition: none !important;
}
select::-ms-expand {
  display: none;
}
.party_emoji {
  position: relative;
  bottom: 8px;
}

.data-main {
  .profile-border {
    border-top: solid 1px #3e3e3e;
  }
  .profile-border2 {
    border-bottom: solid 1px #3e3e3e;
  }
}
.profile-border {
  border-top: solid 1px #c7cacd;
}
.profile-border2 {
  border-bottom: solid 1px #c7cacd;
}
.div-scroll-btn {
  width: calc(100% - 20%);
  width: -moz-available;
}
.scroll {
  /* position: absolute; */
  background-color: #1c1c1f;
  color: white;
  border: none;
}
.rws-container,
.rws-close {
  background-color: white;
  color: black !important;
}
.rws-header {
}
.data-main {
  .rws-container,
  .rws-close {
    background-color: #000;
    color: white !important;
  }
  .rws-header {
    color: white !important;
  }
  .changeImagebottomSheet {
    background-color: #252525 !important;
  }
  .changeList > li {
    background-color: transparent;
    color: #fff;
  }
}

.uploadImage_change {
  background-color: #f3f2f9;
  border-radius: 15px;
  border: 1px solid #f3f2f9;
}
.img_brdr {
  border: solid 0.2px #8270ff;
}

.card-report-pwa_image {
  position: fixed !important;
  backdrop-filter: blur(2px) !important;
  /* // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important; */
  bottom: -2px;
  z-index: 10;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  /* height: calc(100% - 460px) !important; */
  height: 275px !important;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: 495px;
  /* width: calc(100% - 70%); */
  transform: translateX(-1%) !important;
}
.card-report-pwa_profile {
  position: fixed !important;
  backdrop-filter: blur(2px) !important;
  /* // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important; */
  bottom: -2px;
  z-index: 10;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  height: calc(100% - 480px) !important;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: 495px;
  /* width: calc(100% - 70%); */
  transform: translateX(-2%) !important;
}
@media only screen and (max-width: 500px) {
  .card-report-pwa {
    width: 99% !important;
    left: calc(10px - 1px);
  }
  .card-report-pwa_image {
    width: 100% !important;
    left: calc(10px - 7px);
  }
  .card-report-pwa_profile {
    width: 100% !important;
    left: calc(10px - 1px);
  }
}
.changeImagebottomSheet {
  background-color: #fff;
}
.changeList > li {
  background-color: transparent;
  color: #000;
}
.list-group-item {
  padding: 10px 0 !important ;
}
.chng-img {
  width: 90%;
}
.mps {
  padding-left: 1.2rem;
}
.manageGap {
  /* gap: 5px; */
}
@media only screen and (max-width: 469px) {
  .chng-img {
    width: 90%;
  }

  .addProfile {
    height: 240px;
    /* width: 170px; */
  }
}
.infinite-scroll-component::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}
