.Match-screen {
    background: white;
    /* min-height: 100vh; */
}
.data-main{
    .Match-screen {
        background: black;
        /* min-height: 100vh; */
    }
}
.img-Name {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.186667px;
    color: #ffffff;
}

.idealFor {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
}

.idealFor2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #fff;
}


.weekof-Name {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.186667px;
    color: #ffffff;
}

.weekof {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
}

.weekof>span {
    color: #fff;
}

.match-content1 {
    font-family: 'Times';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.045px;
    color: #fff;
}

.match-content2 {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #000;
}

.match-content3 {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.content-color {
    color: #000;
    text-align: center;
}

.content-match-li>li {
    list-style: none;
    margin-left: -2rem;
}

.btnAccept {
    border: #ffffff 1px solid;
    padding: .5rem .5rem .5rem .5rem;
    color: black;
    border-radius: 5px;
    background-color: #ffffff;
}

.btnReject {
    border: red 1px solid;
    padding: .5rem .5rem .5rem .5rem;
    color: red;
    border-radius: 5px;
    background-color: black;
}

.data-main{
    .Dots_user {
        color: #fff;
        cursor: pointer;
        background-color: black;
        border: none;
    }

    .match-content2 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
    }
    .content-color {
        color: #fff;
        text-align: center;
    }
    
    
}
.Dots_user {
    color: #000;
    cursor: pointer;
    background-color: white;
    border: none;
}

.User_Blok {
    background-color: black;
}

.User_contentBlock {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bottomBorderBlock {
    border-bottom: 1px solid #ffffff;
    cursor: pointer;
}

.bottomBorderBlock1 {
    cursor: pointer;
}

.cancelButton {
    border: #ffffff 1px solid;
    background-color: #ffffff;
    color: black;
    padding: 0rem 2rem 0rem 2rem;
    border-radius: 5px;
}

.ReactModal__Overlay {
    inset: 0px;
    z-index: 1000 !important;
    background-color: #1c1c1fb0  !important;
}
/* .ReactModal__Content  {
    position: absolute;
    inset: 50% auto auto 50%; 
    border: none !important;
    background: #1C1C1F !important;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px; 
    transform: translate(-50%, -50%); 
} */
.btnCommunity {
    border: #ffffff 1px solid;
    padding: .5rem .5rem .5rem .5rem;
    color: black;
    border-radius: 5px;
    background-color: #ffffff;
}

/* @media (min-width: 1080px) and (max-width:1980px) {
    .ReactModal__Content  {
        position: absolute;
        inset: 50% auto auto 50%; 
        border: none !important;    
        background: #1C1C1F !important;
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        margin-left: 0% !important;
        transform: translate(-50%, -50%);
        top: 18em !important;
    }
    
}
@media (min-width: 200px) and (max-width:1080px) {
    .ReactModal__Content  {
        position: absolute;
        inset: 50% auto auto 50%; 
        border: none !important;
        background: black !important;
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        margin-left: 0% !important;
        transform: translate(-50%, -50%);
        top: 18rem !important;
    }
    
} */
.Foralign{
    text-align: justify;
    line-height: 25px;
}

.page-item.active {
    color: #fff !important;
  }
  .nav-item>.active {
    border-width: 50px;
    /* // border: solid 2px #8270FF; */
    border-radius: 35px;
    background: #8270FF;
  }
  
  .active > .page-link,
  .page-link.active {
    color: rgb(249, 249, 249) !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }