/* body {
    margin: 0 0 55px 0;
} */

.data-main {
  .navMatch {
    /* position: fixed;
          bottom: 0; */
    width: 100%;
    height: 60px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #000000;
    display: flex;
    overflow-x: auto;
    /* border-top-left-radius: 15px;
          border-top-right-radius: 15px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .navv {
    /* position: fixed;
          bottom: 0; */
    width: 100%;
    height: 80px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #000000;
    display: flex;
    overflow-x: auto;
    border-top: solid 2px #292739;
    z-index: 2;
  }

  .nav__linkk:hover {
    /* background-color: #eeeeee; */
    color: #fff;
  }
  .img_border {
    border: solid 1px #fff;
  }
}
.navMatch {
  /* position: fixed;
      bottom: 0; */
  width: 100%;
  height: 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #000000;
  display: flex;
  overflow-x: auto;
  /* border-top-left-radius: 15px;
      border-top-right-radius: 15px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.navv {
  /* position: fixed;
      bottom: 0; */
  width: 100%;
  height: 80px;
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); */
  background-color: #fff;
  display: flex;
  overflow-x: auto;
  border-top: solid 2px #efefef;
  z-index: 2;
}
.nav__linkk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 16px;
  color: #a9a6a6;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.nav__linkk:hover {
  /* background-color: #eeeeee; */
  color: #000;
}

.active {
  color: #fff;
}

.nav__iconn {
  font-size: 22px;
}

.img_border_active {
  border: solid 1px #8270ff;
}

.data-main {
  .img_border {
    border: solid 1px #fff;
  }
}
.img_border {
  border: solid 1px #000;
}
.img_border_un {
  border: solid 1px #000;
}
