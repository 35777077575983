body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  scrollbar-width: none;
}
html {
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow: auto;
}
html::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  overflow: auto;
}
.rc-anchor-dark {
  background: #000 !important;
  color: #fff !important;
}
.recaptcha-container >.rc-anchor-light {
  background: #fff !important;
  color: #000 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p.font13 {
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 60px;
}

.title1 {
  font-weight: 500 !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  font-size: 9.8px !important;
}
.title2 {
  font-size: 12px !important;
  text-align: justify !important;
}
.title3 {
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-bottom: 0px;
  margin-top: 14px;
  text-decoration: underline;
  cursor: pointer;
}
