.setting-screen {
  min-height: 100vh;
  background-color: #fff;
}

.screen-row {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logout {
  color: #fff;
}

.screenInp {
  color: #000;
}

.screenBootom > a {
  text-decoration: none !important;
}

.settingpage {
  background: black;
  min-height: 100vh;
}

.ageUpdate-Btn {
  background-color: #ffffff;
  border: none;
  padding: 0.2rem 1.1rem 0.2rem 1.1rem;
  border-radius: 5px;
}

.profileIcon {
  color: #ffffff;
}
.SettingName {
  background: #f3f3f9;
  border: 1px solid red !important;
  color: #000;
}
.data-main {
  .SettingName {
    background: #0b0c0c;
    border: 1px solid red !important;
    color: #fff;
  }
  .setting-screen {
    min-height: 100vh;
    background-color: #000;
  }
  .Settingbottom {
    border: 1px solid #29273a !important;
    background: #0b0c0c;
  }
  .screenInp {
    color: #ffffff;
  }
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
.Settingbottom {
  border: 1px solid #f3f3f9 !important;
  background: #f3f3f9;
}

.date-input10 {
  background: #050e17;
  color: #ffffff;
  font-size: 1rem;
  border-bottom: 1px solid #ffffff !important;
  border: none;
  padding: 1em 1em;
  color: #ffffff;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .screen-bg {
    background: black;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #ffffff;
  }
}

.modal {
  font-size: 12px;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.flex-row-username {
  border-radius: 0.3rem;
  border: #ffffff 1px solid;
  height: 33px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.form-row-loc {
  border: none;
  border-bottom: #ffffff 1px solid;
  height: 48px;
  overflow: hidden;
  position: relative;
}
.pac-container {
  background-color: transparent;
  border: solid 1px transparent;
  color: black;
}
.pac-item {
  border-top: 1px solid #989898;
}
.data-main {
  .pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #fff;
  }
  .pac-item-query > span,
  .pac-item {
    color: #fff;
  }
}
.pac-item:hover span {
  color: #000;
}
