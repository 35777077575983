* {
  padding: 0;
  margin: 0;
}
.btn-load {
  padding: 17px;
  font-size: 1.5em;
  cursor: pointer;
  border: 0px;
  position: relative;
  transition: all 0.25s ease;
  background: transparent;
  color: black;
  overflow: hidden;
  border-radius: 10px;
}
.load {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
}
.load::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 28px;
  height: 28px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading1 1s ease infinite;
  z-index: 10;
}
.load::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 1.5px dashed #fff;
  width: 28px;
  height: 28px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading1 2s linear infinite;
  z-index: 5;
}
@keyframes loading1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-load.active {
  transform: scale(0.85);
}
.btn-load.activeLoading .loading {
  visibility: visible;
  opacity: 1;
}
.btn-load .loading {
  opacity: 1;
  visibility: visible;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, #ffffff 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}
