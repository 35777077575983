body{
    /* height: auto; */
}
.Match-screen {
    background: white;
    /* min-height: 100vh; */
}
.data-main{
    .Match-screen {
        background: black;
        /* min-height: 100vh; */
    }
}