.bar {
  width: 50px !important;
  border-radius: 10px;
  height: 5px;
  background-color: #8270ff;
}
.bar-blank {
  width: 50px !important;
  border-radius: 10px;
  height: 5px;
  background-color: #434343;
}

.ctn_btn-gender {
  background: #8270ff;
  font-family: SF Pro Text;
  font-size: 17px;
  color: #ffff;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 20px;
  position: relative;
  /* bottom: 10px; */
  width: 130px;
}
#DOB {
  height: 45px;
}
.ctn_btn-gender-unselect {
  background: #ffffff05 !important;
  font-family: SF Pro Text;
  font-size: 17px;
  letter-spacing: 0em;
  text-align: center;
  border: solid 1px #8270ff;
  border-radius: 20px;
  position: relative;
  /* bottom: 10px; */
  width: 130px;
}

.data-main {
  .card {
    background-color: #1c1c1f !important;
    color: #fff;
  }
  .Add_img_icon {
    font-size: 5rem;
    cursor: pointer;
    color: #fff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent !important;
    /* border: 1px solid #29273a !important; */
    border: none !important; 
    caret-color: rgb(255, 255, 255);
    -webkit-text-fill-color: rgb(255, 255, 255) !important;

    -webkit-box-shadow: 0 0 0 30px #050606  inset !important;
  }
}

.card {
  background-color: #fafafa !important;
  color: #000;
}

.Add_img_icon {
  font-size: 5rem;
  cursor: pointer;
  color: #c9c9c9;
}
.pos_ition_New {
  position: absolute;
  top: 203px;
  margin-left: 80px;
}

.avtar1_new {
  margin-left: 6rem;
  cursor: pointer;
  border: solid 1px #9f93ef;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  position: absolute;
  top: -185px;
  left: 15px;
}
.avtar2_new {
  margin-left: 7rem;
  cursor: pointer;
  border: solid 1px red;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  position: relative;
  top: 135px;
  z-index: 20;
}
.del_ {
  background-color: #25272880;
}

/* birthday  */
.md-mobile-picker-header {
  font-size: 14px;
}

input.md-mobile-picker-input {
  color: initial;
  width: 100%;
  padding: 10px;
  margin: 6px 0 12px 0;
  border: 1px solid #ccc;
  border-radius: 0;
  font-family: arial, verdana, sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

.md-mobile-picker-button.mbsc-button {
  font-size: 13px;
  padding: 0 15px;
  line-height: 36px;
  float: right;
  margin: 6px 0;
  width: 100%;
}

.mbsc-col-no-padding {
  padding-left: 0;
}

.md-mobile-picker-box-label.mbsc-textfield-wrapper-box,
.md-mobile-picker-box-label .mbsc-textfield-wrapper-box,
.md-mobile-picker-inline {
  margin: 6px 0 12px 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: rgba(238, 238, 238, 0.796) !important;
  /* border: 1px solid #dedede; */ 
  caret-color: black;
  border: none !important;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 30px #f8f8fc  inset !important;
}
