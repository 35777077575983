.reportrelation {
    font-family: 'SignPainter';
    font-style: italic;
    font-weight: 600;
    font-size: 55px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 0.1375px; 
}

.week {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 60% !important;
    margin: 100px auto !important;
}

.week-block {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
}

.week-header {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 30px;
    color: #fff;
}

.date {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 34px;
    color: #FFFFFF;
    opacity: 0.7;
}

.date1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 34px;
    color: #FFFFFF;
    opacity: 0.7;
}

.and {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    
}

.called {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
    width: 50% !important;
    margin: 100px auto !important;
    margin-bottom: 100px;
    margin-bottom: 10px !important;
}

.image-section {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto !important;
    /* / width: 300px !important; / */
    height: 300px !important;
}

.border-icon {
    width: 300px !important;
    height: 300px !important;
}

.rel-name {
    font-family: Times;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.week2 {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 60% !important;
    margin: 100px auto !important;
}

.week-block2 {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;
}

.ideal {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
}

.reIdeal {
    color: #fff;
}

.description-section {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    margin: 0 auto !important;
    /* width: 100vw !important; */
}

.data-main{

    .reportrelation {
        font-family: 'SignPainter';
        font-style: italic;
        font-weight: 600;
        font-size: 55px;
        line-height: 78px;
        text-align: center;
        letter-spacing: 0.1375px; 
    }

    
    .section-title { 
        font-family: Gilroy;
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
    }
    
    .section-description {
        /* width: 85% !important; */
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
        mix-blend-mode: normal; 
    }

    .footer-relation {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        color: #FFFFFF;
      }
      .footer{
        color: #fff;
      }
}
.section-title { 
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
}

.section-description {
    /* width: 85% !important; */
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #000;
    mix-blend-mode: normal; 
}
.footer{
    color: #000;
  }
.footer-relation {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #0000 !important;
  }

@media screen and (max-width: 576px) {
    .reportrelation {
        font-size: 34px;
        line-height: 55px;
    }
    .week {
        justify-content: space-around !important;
        margin: 30px auto !important;
    }
    .week-header {
        font-size: 18px;
        line-height: 20px;
    }
    .date {
        font-size: 16px;
        line-height: 18px;
    }
    .week {
        width: 90% !important;
    }
    .border-icon {
        width: 200px !important;
        height: 200px !important;
    }
    .called {
        font-size: 18px;
        line-height: 22px;
        margin: 10px auto !important;
    }
    .rel-name {
        font-size: 25px;
        line-height: 28px;
    }
    .date1 {
        font-size: 16px;
        line-height: 18px;
        margin-right: 2rem !important;
    }
    .ideal {
        font-size: 30px;
        line-height: 33px;
    }
    .section-title {
        font-size: 28px !important;
        line-height: 36px !important;
    }
    .section-description {
        font-size: 15px !important;
        line-height: 24px !important;
    }
    .footer-relation {
        font-size: 13px;
        line-height: 14px;
      }
    .footer-line {
        width: 80% !important;
      }
}
@media (min-width: 577px) and (max-width: 767px) {
    .week {
        justify-content: space-around !important;
        margin: 30px auto !important;
    }
    .week-header {
        font-size: 30px;
        line-height: 25px;
    }
    .called {
        font-size: 30px;
        line-height: 30px;
        margin: 10px auto !important;
    }
}