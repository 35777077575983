::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}

.matchImg {
  position: relative;
}

.img-matches-open {
  height: calc(100vh - 13.5em);
  width: 100%;
  object-fit: cover;
}

.img-matches {
  height: calc(100vh - 12em);
  width: 100%;
  object-fit: cover;
}

.matchInnerbox {
  position: absolute;
  border: 1px solid #000000c7;
  background-color: #000000c7;
  width: 92%;
  bottom: 20px;
  // left: 20px;
  padding: 10px;
  z-index: 1;
  // backdrop-filter: blur(10px);
}

.matchInnerbox1 {
  position: absolute;
  border: 1px solid #464646;
  background-color: #464646;
  width: 80%;
  bottom: 20px;
  // left: 34px;
  padding: 10px;
  z-index: 1;
}

.paraMatch {
  font-size: 12px;
  // opacity: 0.7;
}

.paraMatch1 {
  opacity: 0.7;
}

.paraMatch2 {
  color: #fff !important;
}
.img_filter {
  filter: invert(0%);
}
.data-main {
  .img_filter {
    filter: invert(100%);
  }
  .AboutContent {
    background-color: black;
    transition: linear 2s ease-in-out;
    transition: height 2s;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: transform 0.4s ease-in-out;
    position: relative;
    z-index: 222;
    // height: 12rem !important;
  }
}
.AboutContent {
  background-color: white;
  transition: linear 2s ease-in-out;
  transition: height 2s;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: transform 0.4s ease-in-out;
  position: relative;
  z-index: 222;
  color: black;
  // height: 12rem !important;
}

.AboutContent-New {
  background-color: black;
  transition: linear 2s ease-in-out;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  z-index: 222;
}

.arrowMatchbutton {
  border: none;
  background-color: transparent;
}

.section {
  overflow: scroll;
  transition: height 0.3s ease-out;
  height: 100vh;
  max-height: 1000px;
  scrollbar-width: none !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.section1 {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  transition: height 0.3s ease-out;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: transform 0.4s ease-in-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.section1::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.main-animation {
  transition: transform 40s ease-in-out;
  transform: translateY(-50px);
}

.section.collapsed {
  max-height: 0;
}

.weekOfName1 {
  display: flex;
  justify-content: space-around;
}

.weekof-Name1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.186667px;
  color: #ffffff;
}

.weekof1 {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.match-content11 {
  font-family: "Times";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.045px;
  color: #fff;
}

.match-content21 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.match-content31 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.matchLike {
  // color: invert($color: #000000);
  background-color: #fff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 13px;
  box-shadow: 0px 0px 10px #9f9f9f;
  transition: all 0.2s linear;
}

.matchLike:hover {
  transform: scale(1.2);
  transition: all 0.2s linear;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #ffffff;
  color: black;
}

.ff1 {
  position: relative;
  // top: 110px;
}
.like-unlike {
  position: relative;
}

.icons-like-unlike {
  position: relative;
}

#unlikeLike {
  position: fixed;
  z-index: 999;
  // padding: 0 74px;
  bottom: 68px;
  transform: translate(0%, 0%);
}



.topFix {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: #111418;
}

@media (max-width: 767px) {
  .match_sidebar {
    display: none;
  }

  .topFix {
    width: 100%;
    height: 100vh;
  }

  .topFix .dataContainer {
    height: calc(100vh - 3.5em);
  }

  .topFix .navMatchbottom {
    height: 60px;
  }

  .AboutContent-New,
  .AboutContent,
  .section1,
  .section {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@media (min-width: 300px) and (max-width: 412px) {
  .matchInnerbox {
    position: absolute;
    border: 1px solid #000000c7;
    background-color: #000000c7;
    // width: fit-content;
    bottom: 20px;
    // left: 20px;
    padding: 10px;
    z-index: 1;
  }
}

@media (min-width: 300px) and (max-width: 500px) {
  .matchInnerbox {
    position: absolute;
    border: 1px solid #000000c7;
    background-color: #000000c7;
    // width: fit-content;
    bottom: 20px;
    // left: 20px;
    padding: 10px;
    z-index: 1;
  }

  .img-matches,
  .img-matches-open {
    height: calc(100vh - 12em);
    width: 100%;
    object-fit: cover;
  }

  .AboutContent {
    height: 12em;
  }

  .like-btns1 {
    position: sticky;
    bottom: 3.85em !important;
  }
}

@media (max-width: 767px) {
  .AboutContent {
    height: 9.5em;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .top-btns {
    display: none !important;
  }
}

@media (min-width: 500px) and (max-width: 784px) {
  #unlikeLike {
    position: fixed;
    z-index: 999;
    bottom: 60px;
    width: 100%;
    justify-content: center;
    display: flex;
    transform: translate(-2%, 0%);
  }

  .matchInnerbox {
    position: absolute;
    border: 1px solid #000000c7;
    background-color: #000000c7;
    // width: fit-content;
    bottom: 20px;
    // left: 20px;
    padding: 10px;
    z-index: 1;
  }

  .img-matches,
  .img-matches-open {
    height: calc(100vh - 12em);
    width: 100%;
    object-fit: cover;
  }

  .AboutContent {
    // height: 205px;
    // height: 12em;
  }

  .like-btns1 {
    position: sticky;
    bottom: 61px !important;
  }
}

@media (min-width: 768px) and (max-width: 868px) {
  .AboutContent {
    // height: 185px;
  }

  .like-btns1 {
    position: static;
    bottom: 5px !important;
  }

  .matchInnerbox {
    position: absolute;
    border: 1px solid #000000c7;
    background-color: #000000c7;
    // width: fit-content;
    bottom: 20px;
    // left: 20px;
    padding: 10px;
    z-index: 1;
  }
}

@media (min-width: 768px) and (max-width: 785px) {
  .img-matches,
  .img-matches-open {
    height: calc(100vh - 12em);
    width: 100%;
    object-fit: cover;
  }

  .matchInnerbox {
    position: absolute;
    border: 1px solid #000000c7;
    background-color: #000000c7;
    // width: fit-content;
    bottom: 20px;
    // left: 20px;
    padding: 10px;
    z-index: 1;
  }
}

@media (min-width: 786px) and (max-width: 856px) {
  #unlikeLike {
    position: fixed;
    z-index: 999;
    bottom: 165px;
    transform: translate(15%, 0%);
  }

  .like-btns1 {
    position: sticky;
    bottom: 95px;
  }
}

@media screen and (min-width: 767px) {
  .navMatchbottom {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .like-btns {
    position: sticky;
    bottom: 60px !important;
  }
}

@media (min-width: 576px) and (max-width: 786px) {
  #unlikeLike {
    position: fixed;
    z-index: 999;
    bottom: 63px;
    transform: translate(-29%, 0%);
  }
}

.viewMatch {
  max-height: 100%;
}

.section1::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

.section1 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.advice-text {
  font-size: 12px;
}

// body {
//   margin: 0 0 55px 0;
// }

.nav-match--bottom {
  width: 100%;
  height: 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #464646;
  display: flex;
  overflow-x: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.nav__link-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 16px;
  color: #a9a6a6;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 0rem !important;
}

.nav__link-bottom:hover {
  // / background-color: #eeeeee; /
  color: #fff;
}

.nav__link--active-bottom {
  color: #fff;
}

.nav__icon-bottom {
  font-size: 22px;
}

.like-btns {
  position: sticky;
  bottom: 20px;
}

.like-btns1 {
  position: sticky;
  bottom: 20px;
}

.match-para {
  text-align: left;
  line-height: 25px;
  font-size: 17px;
  padding: 10px;
}

.structure {
  height: 560px;
  border-radius: 10px;
  // width: 500px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nav-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 600;
  transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-item {
  // opacity: 0;
  // transition-property: none !important;
}

div.vert-move {
  animation: fadeIn 1s;
  margin-bottom: 1.2em;
}

.AboutContent .vert-move {
  margin-bottom: 1.5em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.like-btns1 .btn:hover {
  color: black !important;
}

.carousel-indicators {
  top: 2.6em;
  bottom: 0px !important;
}

@media (max-width: 767px) {
  .AboutContent .vert-move {
    margin-bottom: 1.5em;
  }

  div.vert-move {
    margin-bottom: 5.2em;
  }
}

.active {
  color: #fff;
}

.btn-card:hover {
  color: black !important;
}

.data-filter-box-none-data {
  position: relative;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 3rem !important;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.communicate-report > .relationreport-cards {
  display: inline-block;
  height: 450px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  color: white;
  text-align: left;
}
.carousel-indicators {
  top: 10px !important;
}

// crads.ss
.head-light {
  font-family: SF Pro Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

.img_card_div {
  width: 326px;
  top: 205px;
  left: 32px;
  border-radius: 25px;
}

.card_img {
  border-radius: 25px;
}
.Add_btn {
  background-color: #efefef;
  border-radius: 25px;
  color: black;
  width: 150px;
  border: none;
}
.Add_btn_profile {
  background-color: #f1f1f1;
  border-radius: 25px;
  color: black;
  width: 150px;
  border: none;
}
.insta {
  height: 40px;
  width: 40px;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
.main-head-cards {
  position: absolute;
  top: 0px;
  background: #fff;
  width: 100%;
  z-index: 500;
  color: black;
}
.manage_color {
  color: #000;
}
.data-main {
  color: white;

  .manage_color {
    color: #fff;
  }
  .head {
    font-family: SF Pro Text;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
  }
  .main-head-cards {
    position: fixed;
    top: 0px;
    background: #000;
    width: 500px;
    z-index: 500;
  }
  .Add_btn {
    background-color: #1c1c1f;
    border-radius: 25px;
    color: white;
    width: 150px;
    border: none;
  }
  .Add_btn_profile {
    background-color: #1c1c1f;
    border-radius: 25px;
    color: white;
    width: 150px;
    border: none;
  }

  .card_sln {
    color: #fff;
    background-color: #1c1c1f;
    border-radius: 25px;
    position: relative;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.active {
  color: #8270ff !important;
}
.img_bg_back {
  background-color: #fff;
  width: 120px;
  height: 120px;
  border: solid 1px #8270ff;
  border-radius: 100%;
  position: relative;
  z-index: 10;
}
.card_sln {
  color: #000;
  background-color: #efefef;
  border-radius: 25px;
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.matchImg {
  position: relative;
  top: 6%;
}

.pp {
  position: relative;
}

.ReactModal__Overlay {
  // width: 500px;
  // margin: auto;
  opacity: 0;
  // transform: translateX(-100px);
  // transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  // transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  // transform: translateX(100px);
}

.scrl_manage {
  position: fixed;
  width: 485px;
}
@media only screen and (max-width: 500px) {
  .scrl_manage {
    position: fixed;
    width: 96% !important;
  }
}
