.headerRight {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  border-left: solid 1px #3C444F;
  width: 25%;
  background: #0b0d0e;

}

.headerBg {
  background-color: black;
  border-bottom: solid 1px white;
}

.profileHeader {
  border: 1px solid black;
  color: #fff;
}

.profileHeader:hover {
  border: 1px solid black;
  border-radius: 30px;
  background-color: white;
  color: black;
}

.borderForIcon {
  height: 30px;
  width: 30px;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2px 0px 0 7px;
  color: black;
}

.borderForIcon1 {
  height: 30px;
  width: 30px;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2px 0px 0 5px;
  color: black;
}
.borderForIcon1>.a {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px;
}
.borderForIcon11 {
  height: 30px;
  width: 30px;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2px 0px 0 5px;
  color: black;
}
.borderForIcon11>.a {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px;
}

.contentPart {
  height: 100vh;
  overflow: scroll;
  border: 1px solid black;
}

.AdjustContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0px;
}

.Chatprofile {
  color: white;
}

.Chatprofile:hover {
  cursor: pointer;
  background-color: #141414;
}

.chat {
  flex: 2;

  .chatInfo {
    height: 60px;
    background-color: #141414;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 5px;
    color: lightgray;
  }

  .pointerIcon {
    cursor: pointer;
  }

  .chatIcons {
    display: flex;
    gap: 10px;

    img {
      height: 24px;
      cursor: pointer;
    }
  }

  .messages {
    // height: 65vh;
    background-color: black;
    padding: 30px 0px;
    // height: calc(100vh - 250px);
    overflow: scroll;

    .message {
      display: flex;
      gap: 20px;
      // margin-bottom: 20px;

      .messageInfo {
        display: flex;
        flex-direction: column;
        color: gray;
        font-weight: 300;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .Report_chat_button {
        position: fixed !important;
        width: 25%;
      }

      .messageContent {
        max-width: 80% !important;
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          background-color: #333333;
          padding: 10px 20px;
          color: #fff;
          border-radius: 0px 10px 10px 10px;
          max-width: max-content;
        }

        img {
          // width: 50%;
        }
      }

      &.owner {
        flex-direction: row-reverse;

        .messageContent {
          align-items: flex-end;

          p {
            background-color: #fff;
            color: black;
            border-radius: 10px 0px 10px 10px;
          }
        }
      }
    }
  }

  .input {
    width: 25%;
    background-color: #2b2b2b;
    // padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid black;
    // position: fixed;
    // bottom: 0;
    // z-index: 1;
    input {
      width: 100%;
      border: none;
      outline: none;
      color: #D4CECC;
      font-size: 18px;
      background-color: #535353;
      border-radius: 30px;
      margin-left: 1rem;
      padding: 10px;

      &::placeholder {
        // color: lightgray;
      }
    }

    .send {
      display: flex;
      align-items: center;
      gap: 10px;

      .attachfile {
        cursor: pointer;
        font-size: 22px;
        color: #969697 !important;
        transform: rotate(180deg);
      }
    }

    .sendbutton {
      border: none;
      border-radius: 5px;
      padding: 10px 15px;
      background-color: transparent;
      cursor: pointer;
      color: #969697;
      font-size: 25px;
    }
  }
}

.side-chat-btn {
  background: transparent;
  color: white;
  border: none;
}


.nav-item>.active {
  border-width: 50px;
  // border: solid 2px #8270FF;
  border-radius: 35px;
  background: #8270FF;
}

.badget-chat {
  background-color: #191919;
}

.badget-btn {
  background-color: #646464;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 8px 30px;
  font-size: 18px;
}

.text-chat {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid white;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.text-chat span {
  background-color: #000;
  padding: 0 10px;
}

.matches-card {
  width: 150px;
  padding: 120px 20px;
  background-color: #646464 !important;
}

.matches-text {
  text-align: -moz-center;
  text-align: center;
}

.card-report  {
  position: absolute !important;
  backdrop-filter: blur(2px) !important;
  // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important;
  bottom: 0px !important;
  width: calc(100% - 12px) !important;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.card-report>hr {
  border-top: solid 5px #313131;
  border-radius: 5px;
  opacity: 1;
  width: 50px;
}

.card-report>hr:hover {
  cursor: pointer;
}

.badget-btn-report {
  background-color: #2b2b2b;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 8px 30px;
}


.vert-move-card {
  -webkit-animation: mover 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

.vert-move-card {
  -webkit-animation: mover 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0px);

  }

  100% {
    transform: translateY(1000px);
    display: none;
  }
}

@keyframes mover {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(1000px);
  }
}

.vert-move-out-card {
  -webkit-animation: mover-out-card 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-out-card 1s cubic-bezier(0.65, 0, 0.35, 1) both;
 
}

.vert-move-out-card {
  -webkit-animation: mover-out-card 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-out-card 1s cubic-bezier(0.65, 0, 0.35, 1) both; 
}
 
.para-chat-report{
  overflow: auto; 
}
@-webkit-keyframes mover-out-card {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes mover-out-card {
  0% {
    transform: translateY(1000px);

  }

  100% {
    transform: translateY(0px);

  }
}

.userChatInfo:hover {
  cursor: pointer;
}

.last-messsage-p {
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width:768px) and (max-width:930px) {
  .borderForIcon {
    height: 25px;
    width: 25px;
    background-color: aliceblue;
    border-radius: 50%;
    padding: 0px 0px 0px 4px;
    color: black;
  }

  .borderForIcon1 {
    height: 25px !important;
    width: 25px !important;
    background-color: aliceblue;
    border-radius: 50%;
    color: black;
  }
  .borderForIcon11 {
    height: 30px !important;
    width: 30px !important;
    background-color: aliceblue;
    border-radius: 50%;
    color: black;
  }
  .borderForIcon11>.a {
    margin-left: -2px !important;
    margin-bottom: 5px !important;
    margin-right: 0px;
  }
  .borderForIcon1>.a {
    margin-left: -2px !important;
    margin-bottom: 5px !important;
    margin-right: 0px;
  }

  .nameInput {
    font-size: 11px !important;
  }
  .nameInput-chat {
    font-size: 11px !important;
    padding-left: 0px !important;
  }
}
.icon-hand:hover{
  cursor: pointer;
}
.ForCardScroll{
  overflow: scroll;
  height: 24rem;
  margin-bottom: 9px;
}
.nameInput{
  line-height: 12px !important;
}
@media (min-width:767px) and (max-width:900px) {
  .nameInput {
    width: 5em; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    font-size: 9px !important;
  }
}
@media (min-width:900px) and (max-width:1065px) {
  .nameInput {
    width: 7em; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
    font-size: 9px !important;
  }
}
@media (min-width:1065px) and (max-width:1200px) {
  .nameInput {
    font-size: 9px !important;
  }
}
@media (min-width:1200px) {
  .nameInput {
    font-size: 11px !important;
  }
}