.unauth{
    /* background-color: black; */
    min-height: 100vh;
}
.section1-button {
    padding: .5rem 5.5rem .5rem 5.5rem;
    background: black;
    border: 1px solid #ffffff;
    border-radius: 25px;
    color: #ffffff;
}

.section1-button:hover {
    border: 1px solid #ffffff;
}

.data-main{
    .auth_border{
        background-color: black;
        border: 1px solid #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px #fff;
        
    }
}
.auth_border{
    /* background-color: black; */
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #000;
}
.unauthorized{
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
}