input:focus {
  outline: none;
}
.data-main {
  .code-div {
    background: #1d1b30;
    color: white;
  }
  .Head-one {
    font-family: SF Pro Text;
    font-size: 32px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
  }
  .subHeadOne {
    font-family: SF Pro Text;
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
    padding: 0 60px;
  }

  .btn-signup {
    background: black;
    color: white;
    /* padding: 15px 30px; */
    width: 350px;
    height: 56px;
    border-radius: 100px;

    border: 1px gray solid;
  }

  .Phone_head {
    font-family: SF Pro Text;
    font-size: 32px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
  }
  .inputUsername {
    background: rgba(255, 255, 255, 0.02) !important;
    border: 1px solid #ffffff33;
    border-radius: 50px;
    color: #fff;
  }
  .verficationInputCard > input {
    width: 35px !important;
    height: 35px !important;
    text-align: center !important;
    background: black !important;
    border: none;
    border-bottom: 1px solid #fff !important;
    border-radius: 0px !important;
    color: #fff !important;
  }
  .verficationInputCard > input:focus {
    outline: none;
  }

  .numInput {
    /* background: rgba(255, 255, 255, 0.02) !important; */
    /* border: 0px solid rgba(255, 255, 255, 0.2); */
    border: none;
    /* border-radius: 10px; */
    color: white;
    background-color: transparent;
  }
}
.verficationInputCard > input {
  width: 35px !important;
  height: 35px !important;
  text-align: center !important;
  background: white !important;
  border: none;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
  color: #000 !important;
}
.verficationInputCard > input:focus {
  outline: none;
}

.inputUsername {
  background: #9f93ef12 !important;
  border: 1px solid #95959533;
  border-radius: 50px;
  color: #000;
}
.Head-one {
  font-family: SF Pro Text;
  font-size: 32px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}
.subHeadOne {
  font-family: SF Pro Text;
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 60px;
}
.imgDiv {
  position: relative;
}
.imgProp1 {
  position: relative;
  top: 90px;
  left: 150.14px;
  border-radius: 15px;
}
.imgProp2 {
  width: 104.95px;
  height: 125.58px;
  position: relative;
  top: -15.99px;
  left: 236.93px;
  border-radius: 15px;
  /* border: white 1px solid; */
  transform: rotate(10.8deg);
}
.btn-signup {
  background: white;
  color: black;
  /* padding: 15px 30px; */
  width: 350px;
  height: 56px;
  border-radius: 100px;

  border: 1px gray solid;
}
.icon {
  width: 16.85px;
  height: 20px;
  top: 17px;
  left: 84px;
  margin: 0 10px;
}
.footer-signup {
  position: relative;
  top: 80px;
}
.text-footer {
  font-family: BL Melody;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: center;
  color: gray;
}
.f {
  width: 500px;
  height: 260px;
}

.Phone_head {
  font-family: SF Pro Text;
  font-size: 32px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: center;
  color: #000;
}
.country_code {
  width: 150px;
  height: 100px;
  border: solid 1px rgb(128, 128, 128);
}
.numInput {
  /* background: rgba(255, 255, 255, 0.02) !important; */
  /* border: 0px solid rgba(255, 255, 255, 0.2); */
  border: none;
  /* border-radius: 10px; */
  color: black;
  background-color: transparent;
}
.code-div {
  background: #e3e0fa;
  color: black;
}
.card {
  height: 500px;
  overflow: hidden;
}
.card-report-pwa {
  position: fixed !important;
  backdrop-filter: blur(2px) !important;
  /* // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important; */
  bottom: -2px;
  z-index: 10;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  height: calc(100% - 100px) !important;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: 500px;
  /* width: calc(100% - 70%); */
  transform: translateX(-2%) !important;
}
.card-report-pwa_verify {
  position: fixed !important;
  backdrop-filter: blur(2px) !important;
  /* // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important; */
  bottom: -2px;
  z-index: 10;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  height: calc(100% - 100px) !important;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: 500px;
  /* width: calc(100% - 70%); */
  transform: translateX(-2%) !important;
}

@media only screen and (max-width: 500px) {
  .card-report-pwa {
    width: 99% !important;
    left: calc(10px - 1px);
  }
  .card-report-pwa_verify {
    width: 100% !important;
    left: calc(10px - 1px);
  }
}
.vert-move-card-pwa {
  -webkit-animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

.vert-move-card-pwa {
  -webkit-animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes mover-pwa {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(1000px);
    display: none;
  }
}

@keyframes mover-pwa {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(1000px);
  }
}

.vert-move-out-card-pwa {
  -webkit-animation: mover-out-card-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-out-card 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

.vert-move-out-card-pwa {
  -webkit-animation: mover-out-card-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-out-card-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes mover-out-card-pwa {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes mover-out-card-pwa {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(-50px);
  }
}

.code-list {
  font-size: 15px;
  border-bottom: solid 1px gray !important;
}

.phone_input_width {
  width: 75% !important;
}

@media only screen and (max-width: 434px) {
  .phone_input_width {
    width: 100% !important;
  }
  .title1 {
    font-size: 8.4px !important;
  }
  .title3 {
    font-size: 13px !important;
  }
}

/* otp */
