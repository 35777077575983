/* .conatiner-fluid{
    height: 100vh;
} */

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

:root {
  --color-default-dark: #000;
}
@media (prefers-color-scheme: dark) {
  :root {
    --color-default: #000;
  }
}
[data-set-theme="dark"] {
  --color-default: #0000;
}
[data-set-theme="light"] {
  --color-default: #dfff;
}
* {
  font-family: "Roboto" !important;
  cursor: default;
  user-select: none;
}
html,
body {
  background-color: #fff; /* This sets the background color to black */
  /* This sets the text to blue, so you can read it on webpages set to use defaults; white is too hard on my eyes and if you don't put this it will be black on black */
}

html:not(:hover),
body:not(:hover) {
  background-color: #ffff;
}
.data-main {
}

@media (prefers-color-scheme: dark) {
  html,
  body {
    background-color: #000000; /* This sets the background color to black */
    /* This sets the text to blue, so you can read it on webpages set to use defaults; white is too hard on my eyes and if you don't put this it will be black on black */
  }

  html:not(:hover),
  body:not(:hover) {
    background-color: #000000;
  }
  .data-main {
    background: var(--color-default-dark);
    .btn_common {
      background: #8270ff;
      font-family: SF Pro Text;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      border: none;
    }
    .btn_common_unpressed {
      background: transparent;
      font-family: SF Pro Text;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      border: solid 1px #8270ff;
    }
    .ctn_btn {
      background: #8270ff;
      font-family: SF Pro Text;
      font-size: 17px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      /* position: relative;
    bottom: 10px; */
      width: 350px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .btn_common_card {
      background: #8270ff;
      font-family: SF Pro Text;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      border: none;
      width: 100px;
    }
    .btn_common_unpressed_card {
      background: transparent;
      font-family: SF Pro Text;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 20px;
      color: #fff;
      border: solid 1px #8270ff;
      width: 100px;
    }
  }
}
*::-webkit-scrollbar {
  display: none;
}

.scrollhide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

.bg-alternative {
  background-color: #111418 !important;
}

.bg-main {
  background-color: black !important;
}

.ReactModal__Content--after-open {
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: fadeIn 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
}
.ReactModal__Overlay {
  backdrop-filter: blur(2px);
  /* background: rgba(255, 255, 255, 0.348) !important; */
  border: none !important;
}
.data-main {
  .ReactModal__Content {
    background: #1e1e1e !important;
    border: none !important;
    border-radius: 21px !important;
  }
  .clr-text {
    color: white;
  }
  .bg-main,
  .bg-alternative {
    background-color: white !important;
  }
}
.ReactModal__Content {
  background: rgb(255, 255, 255) !important;
  border: none !important;
  border-radius: 21px !important;
}
@keyframes fadeIn {
  0% {
  }

  100% {
    opacity: 1;
  }
}
.btn_common {
  background: #8270ff;
  font-family: SF Pro Text;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  border: none;
}
.btn_common_card {
  background: #8270ff;
  font-family: SF Pro Text;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  border: none;
  width: 100px;
}
.btn_common_unpressed {
  background: transparent;
  font-family: SF Pro Text;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  border: solid 1px #8270ff;
}
.color-manage {
  color: #000;
}

.data-main {
  .btn_common_unpressed_card {
    background: transparent;
    font-family: SF Pro Text;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 20px;
    color: white;
    border: solid 1px #8270ff;
    width: 100px;
  }
  .color-manage {
    color: #fff;
  }
 
}
 
body {
  color: black;
  background-color: #fff;
}
@media (prefers-color-scheme: dark) {
  body {
    color: white;
    background-color: #000;
  }
  .btn_common_unpressed_card {
    background: transparent;
    font-family: SF Pro Text;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 20px;
    color: white;
    border: solid 1px #8270ff;
    width: 100px;
  }
  .color-manage {
    color: #fff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent !important;
    /* border: 1px solid #ffffff33 !important; */
    border: none !important;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
    -webkit-box-shadow: none !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: transparent !important;
  /* border: 1px solid #dedede; */
  border: none !important;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: none !important;
}

.btn_common_unpressed_card {
  background: transparent;
  font-family: SF Pro Text;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 20px;
  color: black;
  border: solid 1px #8270ff;
  width: 100px;
}
.ctn_btn {
  background: #8270ff;
  font-family: SF Pro Text;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  /* position: relative;
  bottom: 10px; */
  width: 350px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"] {
  opacity: 0;
  /* display: none; */
}
.btn-hover:hover {
  cursor: pointer;
}
.dropdown-toggle::after {
  display: none !important;
}

.change-theme-btn {
  position: absolute;
  background-color: transparent;
  border: none;
}
