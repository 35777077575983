#subId {
  min-height: 100vh;
}
.subscription-img {
  /* background-image: url(../../assets/slimg1.jpg); */
  background-size: cover;
  /* background-color: black; */
}

.sub-img {
  display: flex;
  justify-content: center;
  background-image: url(../../assets/sling4.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.sub2-img {
  display: flex;
  justify-content: center;
  background-image: url(../../assets/sling2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  rotate: -90deg;
}

.subcription-bg {
  /* background: black; */
  /* border: 1px solid #ffffff; */
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px #ffffff; */
  /* position: relative; */
}

.suv {
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.BlackImg {
  height: 90px;
  width: 90px;
  background: black;
  border-radius: 100%;
  padding-top: 17px;
}

.BlackImg2 {
  height: 130px;
  width: 130px;
  background: black;
  border-radius: 100%;
  padding-top: 33px;
  rotate: 90deg;
}

.blackImgContent {
  margin-top: 0.7rem;
  line-height: 15px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 31.0606px;
  /* line-height: 37px; */
}

.BlackColor {
  color: #ffffff;
  margin-top: -1rem;
}

.sub-content {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.Sub-content2 > span {
  line-height: 23px;
  text-align: center;
  margin-bottom: 10px;
}

.sub-btn-1 {
  padding: 0.3rem 0rem 0.3rem 0rem;
  background: #ffffff;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 25px;
}

.sub-btn-1:hover {
  border: 1px solid #ffffff;
}

.data-main {
  .sub-btn-2 {
    letter-spacing: 0.04px;
    color: #ffffff;
    padding: 0.3rem 5.5rem 0.3rem 5.5rem;
    border: 1px solid #ffffff;
    border-radius: 25px;
    background: transparent;
  }
  .sub-btn-2:hover {
    border: 1px solid #ffffff;
  }
}
.sub-btn-2 {
  letter-spacing: 0.04px;
  color: #000;
  padding: 0.3rem 5.5rem 0.3rem 5.5rem;
  border: 1px solid #000;
  background: white;
  border-radius: 25px;
}
.sub2Content {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px !important;
}
.sub-btn-2:hover {
  border: 1px solid #000;
}
.star {
  font-size: 10px;
}
