.birthReport {
    background: black;
    min-height: 100vh;
    padding: 0 2em;
}

.birth-Relation {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
}

.birthday {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.055px;
    color: #FFFFFF;
}

.birthdayPara {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #fff;
}

.birthdayPara2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.birthdayPara3 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.7;
}

.birthInp {
    position: relative;
}

.birthName {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.data-main{
    .birthName {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
    }
}

.bithDAteInp{
    padding: .8rem 0rem .8rem 0rem;
    background: black !important;
    color: #FFFFFF;
    border: none;
    margin-top: 1rem;
    border-bottom: #6C6E70 1px solid;
}

.birthButton {
    background: #fff;
    color: black;
    padding: .8rem 2rem .8rem 2rem;
    border: #ffffff 1px solid;
    text-decoration: none !important;
    border-radius: 25px;
}

.birthButton1 {
    background: #fff;
    color: black;
    padding: .8rem 2rem .8rem 2rem;
    border: #ffffff 1px solid;
    text-decoration: none !important;
    border-radius: 25px;
}
.birthButton2 {
    background: #fff;
    color: #112233;
    padding: .5rem 2.5rem .5rem 2.5rem;
    border: #fff 1px solid;
    text-decoration: none !important;
    border-radius: 25px;
}

.BirthDate-Inp:focus {
    outline: none;
    border-color: #fff;
    /* box-shadow: 0px 0px 10px #fff; */
}

@media (min-width:250px) and (max-width:460px) {
   

    .bithDAteInp {
        padding: .5rem .5rem .5rem .5rem !important;

    }

    .birthButton {
        padding: .5rem 1.5rem .5rem 1.5rem !important;
        
        font-size: 11px;
    }
    .birthButton1 {
        background: #ffffff;
        color: black;
        padding: .8rem 2rem .8rem 2rem;
        border: #ffffff 1px solid;
        text-decoration: none !important;
        border-radius: 25px;
        font-size: 11px;
    }
}

@media (min-width:768px) and (max-width:826px) {
    .bithDAteInp {
        padding: .8rem 1.2rem .8rem 1.2rem !important;

    }

    .birthButton {
        padding: .8rem 1.2rem .8rem 1.2rem !important;
        margin-left: -1.2rem;
    }
}


@media screen and  (max-width:330px) {
    .manage{
        margin-top: 12px;
    }
    
}
#birthimg{
  padding: 0 2em;
}
#profileData{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2)
}
.react-datepicker__current-month{
    display: none;
}