.container-chat-app {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;

  .mainChatdiv {
    // background: #000000;
    // border: 1px solid white;
    // border-radius: 10px;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }

  .haderChatApp {
    background-color: #0000;
  }

  .chat {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .chatInfo {
      flex-shrink: 1;
      height: 60px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 10px 5px;
      color: black;

      @media screen and (max-width: "500px") {
        position: relative !important;
        left: 0%;
        top: 0%;
        width: 100%;
        transform: none;
      }
    }

    .pointerIcon {
      cursor: pointer;
    }

    .chatIcons {
      display: flex;
      gap: 10px;

      img {
        height: 24px;
        cursor: pointer;
      }
    }

    .messages {
      flex-shrink: 1;
      // height: 75vh;
      background-color: #efefefef;
      padding: 40px 0px 70px 0px;
      // position: absolute;
      top: 60px;
      // height: calc(100vh - 130px);
      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;

      @media screen and (max-width: "500px") {
        // height: calc(100vh - 130px);
      }

      .message {
        display: block !important;
        gap: 20px;
        // margin-bottom: 20px;

        .messageInfo {
          display: flex;
          flex-direction: column;
          color: gray;
          font-weight: 300;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .messageContent {
          max-width: 98% !important;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .img_div {
            background-color: #000;
            padding: 10px 10px;
            margin-bottom: 10px;
            color: #fff;
            border-radius: 0px 10px 10px 10px;
            width: 50%;
            max-width: max-content;
            white-space: pre-wrap;
            width: 250px;
            object-fit: contain;
            text-align: start;

            img,
            video {
              height: 200px;
            }
          }
          p {
            background-color: #000;
            padding: 10px 20px;
            color: #fff;
            border-radius: 0px 10px 10px 10px;
            width: 70%;
            max-width: max-content;
            white-space: pre-wrap;
          }

          img {
            // width: 50%;
            border-radius: 0px 10px 10px 10px;
          }
          video {
            // width: 50%;
            border-radius: 0px 10px 10px 10px;
          }
        }

        &.owner {
          flex-direction: row-reverse;

          .messageContent {
            align-items: flex-end;
            .img_div {
              background-color: #8270ff;
              padding: 10px 10px;
              margin-bottom: 10px;
              color: #fff;
              border-radius: 10px 0px 10px 10px;
              width: 50%;
              max-width: max-content;
              white-space: pre-wrap;
              width: 250px;
              object-fit: contain;
              text-align: start;

              img,
              video {
                height: 200px;
              }
            }
            p {
              background-color: #8270ff;
              color: white;
              border-radius: 10px 0px 10px 10px;
            }

            img {
              // width: 50%;
              border-radius: 10px 0px 10px 10px;
            }
            video {
              // width: 50%;
              border-radius: 10px 0px 10px 10px;
            }
          }
        }
      }
    }

    .input {
      flex-grow: 1;
      width: 100%;
      background-color: white;
      // padding: 10px 10px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid black;
      position: fixed;
      bottom: 0px;
      width: 500px;
      @media screen and (max-width: "500px") {
        width: 100%;
        transform: none;
      }
      .data-main {
        input {
          width: 100%;
          border: none;
          outline: none;
          color: #d4cecc;
          font-size: 18px;
          background-color: #efefef;
          border-radius: 30px;
          margin-left: 1rem;
          padding: 10px;

          &::placeholder {
            // color: lightgray;
          }
        }
      }
      input {
        width: 100%;
        border: none;
        outline: none;
        color: #000;
        font-size: 18px;
        background-color: #fff;
        border-radius: 30px;
        margin-left: 1rem;
        padding: 10px;

        &::placeholder {
          color: lightgray;
        }
      }

      .send {
        display: flex;
        align-items: center;
        gap: 10px;

        .attachfile {
          cursor: pointer;
          font-size: 22px;
          color: #969697 !important;
          transform: rotate(180deg);
        }
      }

      .sendbutton {
        border: none;
        border-radius: 5px;
        padding: 10px 15px;
        background-color: transparent;
        cursor: pointer;
        color: #969697;
        font-size: 25px;
      }
    }
  }
}

.data-main {
  .container-chat-app {
    background-color: black !important;
    height: 100vh;
    display: flex;
    justify-content: center;

    .mainChatdiv {
      // background: #000000;
      // border: 1px solid white;
      // border-radius: 10px;
      width: 100%;
      height: 100vh;
      display: flex;
      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
    }

    .haderChatApp {
      background-color: #0000;
    }

    .chat {
      flex: 2;

      .chatInfo {
        height: 60px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 10px 5px;
        color: lightgray;
        position: sticky;
        top: 0px;
        width: 500px;
        z-index: 500;
        @media screen and (max-width: "500px") {
          position: relative !important;
          left: 0%;
          top: 0%;
          width: 100%;
          transform: none;
        }
      }

      .pointerIcon {
        cursor: pointer;
      }

      .chatIcons {
        display: flex;
        gap: 10px;

        img {
          height: 24px;
          cursor: pointer;
        }
      }

      .messages {
        // height: 72vh;
        background-color: #1c1c1f;
        padding: 40px 0px 70px 0px;
        // height: calc(100vh - 130px);
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        position: absolute;
        top: 60px;

        @media screen and (max-width: "500px") {
          // height: calc(100vh - 210px);
        }
        .message {
          display: block !important;
          gap: 20px;
          // margin-bottom: 20px;

          .messageInfo {
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .messageContent {
            max-width: 98% !important;
            display: flex;
            flex-direction: column;
            gap: 10px;

            span {
              color: white;
              text-align: end;
            }
            .img_div {
              background-color: #000;
              padding: 10px 10px;
              margin-bottom: 10px;
              color: #fff;
              border-radius: 0px 10px 10px 10px;
              width: 50%;
              max-width: max-content;
              white-space: pre-wrap;
              width: 250px;
              object-fit: contain;
              text-align: start;

              img,
              video {
                height: 200px;
              }
            }

            p {
              background-color: #000;
              padding: 10px 20px;
              color: #fff;
              border-radius: 0px 10px 10px 10px;
              width: 70%;
              max-width: max-content;
              white-space: pre-wrap;
            }

            img {
              // width: 50%;
              border-radius: 0px 10px 10px 10px;
            }
            video {
              // width: 50%;
              border-radius: 0px 10px 10px 10px;
            }
          }

          &.owner {
            flex-direction: row-reverse;

            .messageContent {
              align-items: flex-end;

              .img_div {
                background-color: #8270ff;
                padding: 10px 10px;
                margin-bottom: 10px;
                color: #fff;
                border-radius: 10px 0px 10px 10px;
                width: 50%;
                max-width: max-content;
                white-space: pre-wrap;
                text-align: end;

                img,
                video {
                  height: 200px;
                }
              }

              p {
                background-color: #8270ff;
                color: white;
                border-radius: 10px 0px 10px 10px;
              }

              img {
                // width: 50%;
                border-radius: 10px 0px 10px 10px;
              }
              video {
                // width: 50%;

                border-radius: 10px 0px 10px 10px;
              }
            }
          }
        }
      }

      .input {
        width: 100%;
        background-color: black;
        // padding: 10px 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid black;
        position: fixed;
        bottom: 0px;
        width: 500px;
        @media screen and (max-width: "500px") {
          width: 100%;
          transform: none;
        }

        input {
          width: 100%;
          border: none;
          outline: none;
          color: #d4cecc;
          font-size: 18px;
          background-color: black;
          border-radius: 30px;
          margin-left: 1rem;
          padding: 10px;

          &::placeholder {
            // color: lightgray;
          }
        }

        .send {
          display: flex;
          align-items: center;
          gap: 10px;

          .attachfile {
            cursor: pointer;
            font-size: 22px;
            color: #969697 !important;
            transform: rotate(180deg);
          }
        }

        .sendbutton {
          border: none;
          border-radius: 5px;
          padding: 10px 15px;
          background-color: transparent;
          cursor: pointer;
          color: #969697;
          font-size: 25px;
        }
      }
    }
  }
}
.contentPart-chat-app {
  // height: calc(100vh - 72px);
  // overflow: scroll;
  border: 1px solid black;
}

.contentPart-chat-app1 {
  height: calc(100vh - 44px);
  // overflow: scroll;
  border: 1px solid black;
}
.last-message {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 340px; /* some width */
}

.card-report-pwa {
  position: absolute !important;
  backdrop-filter: blur(2px) !important;
  // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important;
  bottom: 0;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.card-report-pwa > hr {
  border-top: solid 5px #313131;
  border-radius: 5px;
  opacity: 1;
  width: 50px;
}
.data-main {
  .card-report-pwa > hr {
    border-top: solid 5px #747171;
    border-radius: 5px;
    opacity: 1;
    width: 50px;
  }
}

.card-report-pwa > hr:hover {
  cursor: pointer;
}

.badget-btn-report {
  background-color: #2b2b2b;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 8px 30px;
}

.vert-move-card-pwa {
  -webkit-animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

.vert-move-card-pwa {
  -webkit-animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes mover-pwa {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(1000px);
    display: none;
  }
}

@keyframes mover-pwa {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(1000px);
  }
}

.vert-move-out-card-pwa {
  -webkit-animation: mover-out-card-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-out-card 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

.vert-move-out-card-pwa {
  -webkit-animation: mover-out-card-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: mover-out-card-pwa 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes mover-out-card-pwa {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes mover-out-card-pwa {
  0% {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(-50px);
  }
}

.search {
  border-bottom: 1px solid gray;

  .searchForm {
    padding: 10px;

    input {
      background-color: transparent;
      border: none;
      color: white;
      outline: none;

      &::placeholder {
        color: lightgray;
      }
    }
  }
}

.text-chat > span {
  background-color: #0b0d0e;
}

.Report_chat_button {
  background-color: #000;
}

.borderForIcon1 {
  height: 30px;
  width: 30px;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2px 0px 0 5px;
  color: black;
}

.chat-name:hover {
  cursor: pointer;
}

.card-report-chat {
  position: absolute !important;
  backdrop-filter: blur(2px) !important;
  // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important;
  bottom: 44px !important;
  width: calc(100% - 0px) !important;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.card-report {
  position: absolute !important;
  bottom: -115px !important;
  backdrop-filter: blur(2px) !important;
  // box-shadow: 0px -20px 5px 500px rgba(86, 86, 86, 0.874) !important;
  // bottom: 44px !important;
  width: calc(100% - 1px) !important;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  height: 33rem !important;
}

#CardHeight {
  height: 28rem !important;
}

.ForCardScroll-chat {
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  height: 24rem;
  margin-bottom: 9px;
}

#alignButton {
  position: relative;
  z-index: 300;
}

.tab1 {
  height: calc(100vh - 10rem);
}
.time-chat {
  font-size: 12px;
  vertical-align: bottom !important;
  margin-left: 5px !important ;
}

.mange_wid {
  display: flex;
  width: 100%;
  align-items: center;
}
.input-position {
  // position: fixed;
  bottom: 0px;
  width: 500px !important;
  @media screen and (max-width: "500px") {
    position: relative;
    width: 100% !important;
  }
}
