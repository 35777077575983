.Match-screen {
  background: black;
  min-height: 100vh;
}

.img-Name {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.186667px;
  color: #ffffff;
}

.matchImage1 {
  height: 500px;
  width: 440px;
}

.idealFor {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
}

.idealFor2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #fff;
}

.weekof-Name {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.186667px;
  color: #ffffff;
}

.weekof {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.weekof > span {
  color: #fff;
}

.match-content1 {
  font-family: "Times";
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.045px;
  color: #fff;
}

.match-content2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.match-content3 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.content-color {
  color: #fff;
  text-align: center;
}

.content-match-li > li {
  list-style: none;
  margin-left: -2rem;
}

@media (min-width: 300px) and (max-width: 400px) {
  .matchImage1 {
    height: 400px;
    width: 300px;
  }
}

.weekOfName {
  display: flex;
  justify-content: space-around;
}

// ................................data filter................................ //

.dataFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.option {
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + span {
    background-color: #ffffff;
    color: black;
    border: #ffffff 1px solid;

    &:hover,
    &:focus,
    &:active {
      background-color: #ffffff;
    }
  }

  .btn-option {
    display: flex;
    background-color: black;
    color: #ffffff;
    border: #ffffff 1px solid;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }

    padding: 0.8rem 2.5rem 0.8rem 2.5rem;
  }
}

.dataCategory {
  color: black;
  text-align: left;
}

.dataFilter2 {
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + span {
    background-color: black;
    color: #ffffff;
    border-bottom: #ffffff 2px solid;

    &:hover,
    &:focus,
    &:active {
      background-color: black;
    }
  }

  .dataFilterButton {
    display: flex;
    background-color: black;
    color: #6d7278;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }

    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  }
}

.dataFilterMedia {
  display: flex;
  justify-content: space-between;
}

.forBottom {
  border-bottom: 0.01rem solid #6d7278;
}

@media (min-width: 250px) and (max-width: 350px) {
  // .dataFilterMedia {
  //     display: grid;
  //     justify-content: center;
  // }
  .option .btn-option {
    display: flex;
    background-color: black;
    color: #ffffff;
    border: #ffffff 1px solid;
    padding: 0.8rem 1rem 0.8rem 1rem;
    font-size: 12px;
  }

  .dataFilter2 .dataFilterButton {
    display: flex;
    background-color: black;
    color: #6d7278;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
    font-size: 12px;
  }
}

@media (min-width: 351px) and (max-width: 450px) {
  // .dataFilterMedia {
  //     display: grid;
  //     justify-content: center;
  // }
  .option .btn-option {
    display: flex;
    background-color: black;
    color: #ffffff;
    border: #ffffff 1px solid;
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    // font-size: 12px;
  }

  .dataFilter2 .dataFilterButton {
    display: flex;
    background-color: black;
    color: #6d7278;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
    // font-size: 12px;
  }
}

// ..................range slider.............................//
.horizontal-slider {
  width: 100%;
  height: 50px;
}

.example-thumb {
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  background-color: #8270ff;
  color: white;
  border: solid 1px;
  cursor: pointer;
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: #8270ff;
  outline: none;
  color: #fff !important;
}

.example-track {
  position: relative;
  background: #6d7278;
}

.example-track.example-track-1 {
  background: #8270ff;
}

.horizontal-slider .example-track {
  top: 23px;
  height: 2px;
  border-radius: 10px;
}

.horizontal-slider .example-thumb {
  top: 15px;
  width: 20px;
  height: 20px;
  line-height: 19px;
  border-radius: 100%;
}

// ............................. distance range...........................//
.distance-slider {
  width: 100%;
  height: 50px;
}

.example-thumb2 {
  font-size: 0.5rem;
  font-weight: 800;
  text-align: center;
  background-color: #8270ff;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  border: solid 1px;
}

.example-thumb2.active {
  background-color: #8270ff;
  outline: none;
  color: #fff !important;
}

.example-track2 {
  position: relative;
  background: #6d7278;
}

.example-track2.example-track2-0 {
  background: #8270ff;
}

.example-track2.example-track2-1 {
  background: #6d7278;
}

.distance-slider .example-track2 {
  top: 12px;
  height: 2px;
  border-radius: 10px;
}

.distance-slider .example-thumb2 {
  width: 25px;
  height: 25px;
  line-height: 24px;
  border-radius: 100%;
}

//............................ chip .......................................//
.containerChip {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  margin: 0.25rem;
  border: 1px solid #8270ff;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 25px !important;
}

.rmd-icon--font {
  color: #0606063b !important;
}

.data-main {
  .rmd-chip--solid {
    color: #ffffff;
  }

  .rmd-icon--before {
    margin-right: 0.5rem;
    margin-right: var(--rmd-icon-text-spacing, 0.5rem);
    color: #ffffff !important;
  }

  .chipButtonId {
    background-color: black;
  }
  .img_clr {
    filter: invert(0%);
  }
  .img_clr_sub {
    filter: invert(100%);
  }
}

.img_clr {
  filter: invert(100%);
}

.img_clr_sub {
  filter: invert(0%);
}

.rmd-chip--solid {
  color: #000;
}

.rmd-icon--before {
  margin-right: 0.5rem;
  margin-right: var(--rmd-icon-text-spacing, 0.5rem);
  color: #000 !important;
}

.chipButtonId {
  background-color: white;
}

.datafilterBg {
  background-color: black;
}

.dataCategory1 {
  color: #224a6f;
}

.containerChip {
  // display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  height: 65vh;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  justify-content: center;
}

// ...................react carousel ................................................//

.cardTinder {
  background-color: black;
  min-height: 100vh;
}

.button-container {
  position: relative;
}

.match_Carousel {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 12rem;
}

.preButton {
  background-color: black;
  border: none;
  color: #ffffff;
  font-size: 3.5rem;
}

.nextButton {
  background-color: black;
  border: none;
  color: #ffffff;
  font-size: 3.5rem;
}

.left_button_none {
  border: none;
}

.match_Carousel_arrow {
  display: flex;
  justify-content: space-around;
  top: 7rem;
}

.check_tickMark {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  background-color: black;
}

.check_crossMark {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  background-color: black;
}

.icon_match_01 {
  font-size: 3.5rem;
}

.carousel_height_0 {
  margin-top: 50px;
}

@media (min-width: 250px) and (max-width: 300px) {
  .match_Carousel {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 8rem;
  }

  .matchImage1 {
    height: 300px;
    width: 130px;
  }

  .preButton {
    background-color: black;
    border: none;
    color: #ffffff;
    font-size: 1.8rem;
  }

  .nextButton {
    background-color: black;
    border: none;
    color: #ffffff;
    font-size: 1.8rem;
  }

  .weekOfName {
    display: grid;
  }

  .weekof-Name {
    text-align: center;
    margin-top: 1rem;
  }
}

@media (min-width: 301px) and (max-width: 400px) {
  .match_Carousel {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 8rem;
  }

  .matchImage1 {
    height: 400px;
    width: 190px;
  }

  .preButton {
    background-color: black;
    border: none;
    color: #ffffff;
    font-size: 1.8rem;
  }

  .nextButton {
    background-color: black;
    border: none;
    color: #ffffff;
    font-size: 1.8rem;
  }

  .weekOfName {
    display: grid;
  }

  .weekof-Name {
    text-align: center;
    margin-top: 1rem;
  }
}

@media (min-width: 401px) and (max-width: 600px) {
  .match_Carousel {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 8rem;
  }

  .matchImage1 {
    height: 400px;
    width: 260px;
  }

  .preButton {
    background-color: black;
    border: none;
    color: #ffffff;
    font-size: 2rem;
  }

  .nextButton {
    background-color: black;
    border: none;
    color: #ffffff;
    font-size: 2rem;
  }

  // .carousel-indicators [data-bs-target] {
  //     box-sizing: content-box;
  //     flex: 0 1 auto;
  //     width: 30px;
  //     height: 5px;
  //     margin-top: -23rem !important;
  //     padding: 0;
  //     margin-right: 3px;
  //     margin-left: 3px;
  //     margin-top: -23rem !important;
  //     text-indent: -999px;
  //     cursor: pointer;
  //     background-color: #ffffff !important;
  //     background-clip: padding-box;
  //     border: 2px solid #ffffff !important;
  //     border-radius: 5px !important;
  //     border-top-color: currentcolor;
  //     border-top-style: none;
  //     border-top-width: 0px;
  //     border-bottom-color: currentcolor;
  //     border-bottom-style: none;
  //     border-bottom-width: 0px;
  //     border-top: 10px solid transparent;
  //     border-bottom: 10px solid transparent;
  //     opacity: 0.5;
  //     transition: opacity 0.6s ease;
  //   }
}

@media (min-width: 700px) and (max-width: 990px) {
  .matchImage1 {
    height: 600px;
    width: 550px;
  }
}

@media (min-width: 991px) and (max-width: 1096px) {
  .matchImage1 {
    height: 500px;
    width: 348px;
  }
}

@media (min-width: 1097px) and (max-width: 1200px) {
  .matchImage1 {
    height: 500px;
    width: 400px;
  }
}

// .carousel-indicators [data-bs-target] {
//     box-sizing: content-box;
//     flex: 0 1 auto;
//     width: 30px;
//     height: 5px ;
//     padding: 0;
//     margin-right: 3px;
//     margin-left: 3px;
//     margin-top: -23rem !important;
//     text-indent: -999px;
//     cursor: pointer;
//     background-color: black !important;
//     background-clip: padding-box;
//     border: 2px solid black!important;
//     border-radius: 5px !important;
//     border-top-color: currentcolor;
//     border-top-style: none;
//     border-top-width: 0px;
//     border-bottom-color: currentcolor;
//     border-bottom-style: none;
//     border-bottom-width: 0px;
//     border-top: 10px solid transparent;
//     border-bottom: 10px solid transparent;
//     opacity: .5;
//     transition: opacity .6s ease;
// }
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem !important;
  height: 2em !important;
  background-repeat: no-repeat;
  background-position: 50% !important;
  background-size: 60% 60% !important;
  background-color: black !important;
  border-radius: 50%;
}

@media (min-width: 700px) and (max-width: 992px) {
  // .carousel-indicators [data-bs-target] {
  //     box-sizing: content-box;
  //     flex: 0 1 auto;
  //     width: 30px;
  //     height: 5px;
  //     padding: 0;
  //     margin-right: 3px;
  //     margin-left: 3px;
  //     margin-top: -35rem !important;
  //     text-indent: -999px;
  //     cursor: pointer;
  //     background-color: black !important;
  //     background-clip: padding-box;
  //     border: 2px solid black!important;
  //     border-radius: 5px !important;
  //     border-top-color: currentcolor;
  //     border-top-style: none;
  //     border-top-width: 0px;
  //     border-bottom-color: currentcolor;
  //     border-bottom-style: none;
  //     border-bottom-width: 0px;
  //     border-top: 10px solid transparent;
  //     border-bottom: 10px solid transparent;
  //     opacity: 0.5;
  //     transition: opacity 0.6s ease;
  //   }
}

@media (min-width: 600px) and (max-width: 699px) {
  // .carousel-indicators [data-bs-target] {
  //     box-sizing: content-box;
  //     flex: 0 1 auto;
  //     width: 30px;
  //     height: 5px;
  //     padding: 0;
  //     margin-right: 3px;
  //     margin-left: 3px;
  //     margin-top: -29rem !important;
  //     text-indent: -999px;
  //     cursor: pointer;
  //     background-color: black !important;
  //     background-clip: padding-box;
  //     border: 2px solid black!important;
  //     border-radius: 5px !important;
  //     border-top-color: currentcolor;
  //     border-top-style: none;
  //     border-top-width: 0px;
  //     border-bottom-color: currentcolor;
  //     border-bottom-style: none;
  //     border-bottom-width: 0px;
  //     border-top: 10px solid transparent;
  //     border-bottom: 10px solid transparent;
  //     opacity: 0.5;
  //     transition: opacity 0.6s ease;
  //   }
}

@media (max-width: 386px) {
  .containerChip {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .rmd-chip__content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem !important;
  }
}

@media (min-width: 387px) and (max-width: 576px) {
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .rmd-chip__content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.8rem !important;
  }
}

@media (min-width: 577px) and (max-width: 776px) {
  .containerChip {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }

  .rmd-chip__content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.9rem !important;
  }
}

.data-filter-box {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #292929 !important;
  border: none !important;
  rotate: 90deg;
  padding: 5px !important;
}
.gender-btn-1-filter {
  position: fixed;
  bottom: 70px;
}

.fix_width {
  // width: calc(100vw - 70vw);
  width: 500px !important;
}

@media only screen and (max-width: 450px) {
  .ReactModal__Content {
    width: 90% !important;
  }
}

.height-adj {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

@media only screen and (max-width: 500px) {
  .height-card {
    // min-height: 62vh;
    height: 62vh;
  }
  .fix_width {
    width: 100% !important;
  }
}

@media only screen and (min-width: 400px) {
  .height-card {
    height: 60vh;
  }
  // .fix_width {
  //   width: calc(100vw - 50vw);
  // }
}

@media only screen and (min-width: 360px) {
  .height-card {
    height: 62vh;
  }
  // .fix_width {
  //   width: calc(100vw - 50vw);
  // }
}
/* Extra small devices (phones, 600px and down) */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .height-card {
    height: 62vh;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .height-card {
    height: 62vh;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .height-card {
    height: 62vh;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .height-card {
    height: 62vh;
  }
}
@media only screen and (min-width: 1300px) {
  .height-card {
    height: 62vh;
  }
}
@media (max-height: 490px) {
  .height-card {
    height: 350px;
  }
  // .fix_width {
  //   width: calc(100vw - 50vw);
  // }
}

@media (max-height: 590px) {
  .btn_actions {
    padding-top: 0px !important;
  }
  .height-card {
    height: 55vh;
  }
  .fix_width {
    // width: calc(100vw - 50vw);
  }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 60vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}

// Minimum height */
@media (min-height: 765px) {
  .height-card {
    height: 66vh;
  }
  // .fix_width {
  //   width: calc(100vw - 50vw);
  // }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 70vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}

/* Maximum height */
@media (min-height: 885px) {
  .height-card {
    height: 70vh;
  }

  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 70vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}
@media (min-height: 991px) {
  .height-card {
    height: 74vh;
  }
  .fix_width {
    // width: calc(100vw - 40vw);
  }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 70vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 75vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}

@media (min-height: 1035px) {
  .height-card {
    height: 77vh;
  }
  .fix_width {
    // width: calc(100vw - 60vw);
  }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 80vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}

@media (min-height: 1219px) {
  .height-card {
    height: 80vh;
  }
  // .fix_width {
  //   width: calc(100vw - 50vw);
  // }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: 80vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}

@media (min-height: 1419px) {
  .height-card {
    height: calc(100vh - 20vh);
  }
  // .fix_width {
  //   width: calc(100vw - 50vw);
  // }
  .containerChip {
    // display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    height: calc(100vh - 15vh);
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    justify-content: center;
  }
}
